import { connect } from 'react-redux';

import { getFirstRelatedSectionByIdKey } from 'Utils/content/item-util';

import MagazineRelated from 'Webapp/shared/app/views/section/magazine/magazine-related';
import { RelatedSectionType } from 'Webapp/enums';

const mapStateToProps = ({ sections }, { section }) => {
  const curatorMagazineSection = getFirstRelatedSectionByIdKey(
    section,
    sections.entries,
    RelatedSectionType.PROFILE_MAGAZINES,
  );

  const curatorStoryboardSection = getFirstRelatedSectionByIdKey(
    section,
    sections.entries,
    RelatedSectionType.PROFILE_STORYBOARDS,
  );

  return {
    curatorMagazineSection,
    curatorStoryboardSection,
  };
};

const MagazineRelatedContainer = connect(mapStateToProps)(MagazineRelated);

export default MagazineRelatedContainer;
