import React from 'react';
import PropTypes from 'prop-types';

import Icon from './icon';

const FediverseIcon = ({ color, ...props }) => (
  <Icon
    name="pin"
    {...props}
    viewBox="0 0 22 22"
    fill="none"
    renderComponent={() => (
      <>
        <rect width="22" height="22" />
        <circle
          cx="11.25"
          cy="11.25"
          r="4.15"
          stroke={color}
          strokeWidth="1.8"
        />
        <circle cx="5.125" cy="17.375" r="1.875" fill={color} />
        <circle cx="17.625" cy="4.875" r="1.875" fill={color} />
        <path
          d="M9.09674 20.1443C12.1945 20.8807 14.7893 19.9167 16.3649 18.8235C17.9702 17.7618 19.1654 16.2367 19.8258 14.4363C20.4861 12.636 20.572 10.9017 20.1268 9.01225"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4597 2.37201C10.3659 1.61906 7.76598 2.5693 6.1846 3.654C4.5737 4.70715 3.37031 6.22587 2.70037 8.02269C2.03043 9.81951 1.93526 11.5533 2.37038 13.4451"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  />
);

FediverseIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string.isRequired,
};

FediverseIcon.defaultProps = {
  className: '',
};

export default FediverseIcon;
