import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getWindow from 'Utils/get-window';
import { noOpFn } from 'Utils/no-op-fn';

class CopyToClipboard extends Component {
  constructor(props) {
    super(props);

    this.hiddenInput = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.stopPropagation();
    this.hiddenInput.current.select();
    getWindow().document.execCommand('copy');
    e.target.focus();
    this.props.onClick();
  }

  render() {
    return (
      <div className="copy-to-clipboard" onClick={this.handleClick}>
        <input
          style={{ position: 'absolute', opacity: 0, top: -9999, left: -9999 }}
          value={this.props.value || ''}
          ref={this.hiddenInput}
          tabIndex={-1}
          readOnly
        />
        {this.props.children}
      </div>
    );
  }
}

CopyToClipboard.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

CopyToClipboard.defaultProps = {
  value: '',
  onClick: noOpFn,
};

export default CopyToClipboard;
