import React from 'react';
import CopyToClipboard from 'ComponentLibrary/buttons/copy-to-clipboard';
import styled from '@emotion/styled';
import { BODY_TYPES } from 'Style/typography';
import { SPACING } from 'Style/spacing';
import { TEXT_COLORS } from 'Style/colors';
import FediverseIcon from 'ComponentLibrary/icons/fediverse';

import withT from 'ComponentLibrary/hocs/withT';
import withToast from 'Webapp/shared/app/hocs/withToast';

const FederatedName = styled.div<{ overlay: boolean; inline?: boolean }>(
  BODY_TYPES.SMALL_STANDARD,
  {
    padding: SPACING.MEDIUM,
    gap: SPACING.MEDIUM,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  ({ inline }) => ({ display: inline ? 'inline-flex' : 'flex' }),
  ({ overlay }) => {
    if (overlay) {
      return {
        color: TEXT_COLORS.overlay,
        '&:hover': {
          background: 'rgba(255,255,255,0.1)',
        },
      };
    }
    return {
      '&:hover': {
        background: 'rgba(0,0,0,0.1)',
      },
    };
  },
);

const CopyFederatedUsername: React.FC<{
  username: string;
  toastShowInfoAction: (a: string) => void;
  overlay: boolean;
  tKey?: string;
  inline?: boolean;
  t: (a: string) => string;
}> = ({ username, toastShowInfoAction, overlay, inline, tKey, t }) => (
  <CopyToClipboard
    value={username}
    onClick={() => toastShowInfoAction(t(tKey || 'federated_username_copied'))}
  >
    <FederatedName overlay={overlay} inline={inline}>
      <FediverseIcon
        color={overlay ? TEXT_COLORS.overlay : TEXT_COLORS.secondary}
        size={22}
      />
      {username || ''}
    </FederatedName>
  </CopyToClipboard>
);

export default withT(withToast(CopyFederatedUsername));
