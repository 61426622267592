import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_SUBHEADING_TYPES } from 'Style/typography';

// Utils
import SectionUtil from 'Utils/content/section-util';
import isIntegerMatch from 'Utils/is-integer-match';

import PageClasses from 'Webapp/shared/app/components/page-classes';
import NoContentGuard from 'Webapp/shared/app/components/no-content-guard';
import ItemList, {
  ITEM_LIST_STYLE_MODIFIERS,
} from 'Webapp/shared/app/views/section/item-list';
import LoadMoreScrollToBottomTracker from 'Webapp/shared/app/components/load-more-scroll-to-bottom-tracker';
import VisibilityScrollToBottomTracker from 'Webapp/shared/app/components/visibility-scroll-to-bottom-tracker';
import MagazineFooter from './magazine-footer';

import withT from 'ComponentLibrary/hocs/withT';
import withContentVeil from 'Webapp/shared/app/hocs/withContentVeil';
import withScrollOverlay from 'Webapp/shared/app/hocs/withScrollOverlay';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectCurrentUser from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const MagazineContent = styled(PageClasses)({
  marginTop: SPACING.BASE6X,
  marginBottom: SPACING.BASE6X,
  position: 'relative',
});

const StyledSubHeader = styled.h2(UI_SUBHEADING_TYPES.LARGE_STANDARD, {
  paddingBottom: SPACING.XLARGE,
  overflowWrap: 'break-word',
});

function MagazineBody({
  section,
  items,
  contributors,
  uid,
  usageTrackReachedSectionEnd,
  isLoading,
  loadNextPage,
  neverLoadMore,
  isAmp,
  renderContentVeil,
  veilShown,
  setPositionTopValue,
  isAuthenticated,
  isPhone,
  renderScrollOverlay,
  t,
  currentUserData,
  featureFlags,
}) {
  const magazineBodyRef = useRef();
  const isPrivateContributorMagazine =
    section.isPrivate && !isIntegerMatch(section.userid, uid);

  const { title } = section;

  const itemsToRender = SectionUtil.itemsToRender(
    section,
    items,
    currentUserData,
  );
  const hasItemsToRender = itemsToRender.length > 0;

  const pageClasses = classNames({
    'section-view': true,
    amp: isAmp,
  });

  if (!isLoading) {
    setPositionTopValue();
  }

  const pauseAutoLoad = !isLoading && neverLoadMore;
  const bodyY = magazineBodyRef.current
    ? magazineBodyRef.current.getBoundingClientRect().y
    : 0;

  return (
    <React.Fragment>
      <MagazineContent className={pageClasses} ref={magazineBodyRef}>
        {isPhone && !isAmp && !isAuthenticated && renderScrollOverlay(bodyY)}
        {!featureFlags.NEW_MAGAZINE_COVER && isPrivateContributorMagazine && (
          <p className="section-content__private-message ui-text--supporting">
            {t('this_magazine_is_private')}
          </p>
        )}
        <StyledSubHeader>
          {t('magazine_subheader_title', { title })}
        </StyledSubHeader>
        <NoContentGuard hasContent={hasItemsToRender}>
          <ItemList
            section={section}
            items={itemsToRender}
            sectionHasContributors={contributors.length > 0}
            styleModifier={ITEM_LIST_STYLE_MODIFIERS.GRID}
          />
          {loadNextPage && !pauseAutoLoad && !isAmp && !veilShown && (
            <LoadMoreScrollToBottomTracker
              immediateInfinite
              loadNext={loadNextPage}
              isLoading={isLoading}
              onScrolledToBottom={() => usageTrackReachedSectionEnd(section)}
              waitUntilMoved={false}
            />
          )}
          {pauseAutoLoad && (
            <VisibilityScrollToBottomTracker
              onScrolledToBottom={() => usageTrackReachedSectionEnd(section)}
            />
          )}
        </NoContentGuard>
      </MagazineContent>
      {veilShown ? (
        renderContentVeil(
          <MagazineFooter
            section={section}
            withVeil
            contributors={contributors}
          />,
        )
      ) : (
        <MagazineFooter
          contributors={contributors}
          section={section}
          withVeil={false}
        />
      )}
    </React.Fragment>
  );
}

MagazineBody.propTypes = {
  section: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  contributors: PropTypes.array,
  uid: PropTypes.number.isRequired,
  usageTrackReachedSectionEnd: PropTypes.func.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  neverLoadMore: PropTypes.bool.isRequired,
  isAmp: PropTypes.bool,
  setPositionTopValue: PropTypes.func.isRequired,
  renderContentVeil: PropTypes.func.isRequired,
  veilShown: PropTypes.bool,
  renderScrollOverlay: PropTypes.func.isRequired,
  isPhone: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  t: PropTypes.func.isRequired,
  currentUserData: PropTypes.object,
  featureFlags: PropTypes.object,
};

MagazineBody.defaultProps = {};

export default connector(
  connectAuthentication,
  connectResponsive,
  connectAmp,
  connectCurrentUser,
  connectFeatureFlags,
)(withScrollOverlay(withContentVeil(withT(MagazineBody))));
