import React, { useEffect } from 'react';

import SectionUtil from 'Webapp/utils/content/section-util';
import MagazineHeader from 'Webapp/shared/app/containers/section/magazine/magazine-header';
import MagazineRelated from 'Webapp/shared/app/containers/section/magazine/magazine-related';
import MagazineBody from 'Webapp/shared/app/containers/section/magazine/magazine-body';

import {
  ACCESSORY_SECTIONS,
  getAccessorySections,
  accessorySectionMagazineId,
} from 'Webapp/shared/concepts/accessory-sections';

// HOCs
import withBreadcrumbs from 'Webapp/shared/app/hocs/withBreadcrumbs';
import withToast from 'Webapp/shared/app/hocs/withToast';

import connector from 'Utils/connector';
import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectAmp, {
  ConnectAmpProps,
} from 'Webapp/shared/app/connectors/connectAmp';
import connectOnboardingFlow, {
  ConnectOnboardingFlowProps,
} from 'Webapp/shared/app/connectors/connectOnboardingFlow';
import connectAcceptMagazineInvite, {
  ConnectAcceptMagazineInviteProps,
} from 'Webapp/shared/app/connectors/connectAcceptMagazineInvite';

interface MagazinePassedProps {
  section: Flipboard.Section;
  items: Array<Flipboard.Item>;
  loadNextPage: Promise<void>;
}
type MagazineProps = ConnectAuthenticationProps &
  ConnectFeatureFlagsProps &
  ConnectModalProps &
  ConnectAmpProps &
  ConnectOnboardingFlowProps &
  ConnectAcceptMagazineInviteProps &
  MagazinePassedProps & {
    setBreadcrumbs: (options: { section: Flipboard.Section }) => Promise<void>;
  };

const Magazine: React.FC<MagazineProps> = ({
  section,
  items,
  loadNextPage,
  setBreadcrumbs,
  modalLoginComplete,
  isAmp,
  startOldAcceptMagazineFlow,
  startAcceptMagazineFlow,
  onboardingData,
  featureFlags,
  uid,
}) => {
  const remoteid = section.invite
    ? (section.requestedRemoteId as Flipboard.SectionId)
    : (section.remoteid as Flipboard.SectionId);
  const contributors = getAccessorySections(
    ACCESSORY_SECTIONS.MAGAZINE_CONTRIBUTORS,
    accessorySectionMagazineId(remoteid),
  );

  // On Mount
  useEffect(() => {
    setBreadcrumbs({
      section,
    });

    initiateShowInvite();
  }, []);

  // On Update
  useEffect(() => {
    initiateShowInvite();
  }, [section?.remoteid, section?.invite]);

  useEffect(() => {
    if (modalLoginComplete) {
      initiateShowInvite();
    }
  }, [modalLoginComplete]);

  const { author } = section;
  const allContributors = author ? [author, ...contributors] : contributors;

  const isCurrentUserContributor = !!(
    uid &&
    (SectionUtil.isMagOwner(section, uid || 0) ||
      allContributors.find((x) => x.userid?.toString() === uid.toString()))
  );

  const initiateShowInvite = () => {
    if (section?.remoteid && section?.invite) {
      if (isCurrentUserContributor) {
        return;
      }
      if (featureFlags.INVITE_MAGAZINE_CONTRIBUTOR) {
        if (onboardingData?.acceptMagazineInviteConsented) {
          startAcceptMagazineFlow(section);
        }
      } else {
        startOldAcceptMagazineFlow(section);
      }
    }
  };

  return (
    <div>
      <MagazineHeader
        section={section}
        isCurrentUserContributor={isCurrentUserContributor}
        contributors={contributors}
      />
      <MagazineBody
        section={section}
        contributors={contributors}
        items={items}
        loadNextPage={loadNextPage}
      />
      {!isAmp && <MagazineRelated section={section} />}
    </div>
  );
};

export default connector(
  connectAuthentication,
  connectModal,
  connectAuthentication,
  connectFeatureFlags,
  connectAmp,
  connectOnboardingFlow,
  connectAcceptMagazineInvite,
)(withToast(withBreadcrumbs(Magazine)));
