import { Connector } from 'Webapp/utils/connector';
import {
  getMagazineSubscriptionsSelector,
  getMagazineSubscriptionsLoadedSelector,
  getMagazineSubscriptionsLoadingSelector,
  loadMagazineSubscriptions,
  toggleMagazineSubscription,
} from 'Webapp/shared/concepts/magazine-subscriptions';

const selectors = {
  subscriptions: getMagazineSubscriptionsSelector,
  loaded: getMagazineSubscriptionsLoadedSelector,
  loading: getMagazineSubscriptionsLoadingSelector,
};

const actions = {
  toggleMagazineSubscription,
  loadMagazineSubscriptions,
};

export type ConnectMagazineSubscriptionProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectMagazineSubscription: Connector = {
  selectors,
  actions,
};

export default connectMagazineSubscription;
