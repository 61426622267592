import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { WindowScroll } from 'Utils/window-scroll';
import getWindow from 'Utils/get-window';
import HocUtil from 'Utils/hoc-util';
import scrollTo from 'Utils/scroll-to';

import Button from 'Webapp/shared/app/components/button';
import CaretIcon, { CARET_DIRECTIONS } from 'ComponentLibrary/icons/caret';
import { TEXT_COLORS } from 'Style/colors';

function withScrollOverlay(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.handleViewportChange = this.handleViewportChange.bind(this);
      this.scroller = new WindowScroll(this.handleViewportChange);
      this.state = { scrollY: 0, hideScroll: false };
      this.renderScrollOverlay = this.renderScrollOverlay.bind(this);
    }

    componentDidMount() {
      this.scroller.subscribe();
    }

    componentWillUnmount() {
      this.scroller.unsubscribe();
    }

    handleViewportChange() {
      const scrollY = getWindow().scrollY;
      if (scrollY > 100) {
        this.setState({ hideScroll: true });
      }
      this.setState({ scrollY });
    }

    /**
     *
     * @param {*} bodyY: Y coordinate on page of the top of the body content.
     * @param {*} itemCount & @param {*} itemType: Used to hint for the user what content is available.
     * @returns
     */
    renderScrollOverlay(bodyY, itemCount = 0, itemTypeLabel = null) {
      if (this.state.hideScroll) {
        return <div>{null}</div>;
      }
      const overlayOpacityPercentage =
        this.state.scrollY < 100 ? 100 - this.state.scrollY : 0;
      return (
        /* Two layer gradient, bottom layer applies a general transparent opacity, whose height is tied to the parent div being overlayed.
         * Inner layer sets a gradient with fixed height attached to the bottom of the viewport.
         */
        <div
          className="scroll-prompt-overlay"
          style={{
            background: `rgba(0, 0, 0,${overlayOpacityPercentage * 0.6}%)`,
          }}
        >
          <div
            className="scroll-prompt-overlay__gradient"
            style={{ opacity: `${overlayOpacityPercentage}%` }}
          >
            <div className="scroll-prompt-overlay__text">
              {itemCount > 0 && itemTypeLabel && (
                <div>
                  <span>
                    Continue to read
                    <br />
                  </span>
                  <span>
                    {`${itemCount} stories in this ${itemTypeLabel}`}
                    <br />
                  </span>
                </div>
              )}

              <Button
                name="jump_to_body"
                onClick={() => scrollTo(0, bodyY - 100)}
              >
                <CaretIcon
                  size={44}
                  color={TEXT_COLORS.overlay}
                  direction={CARET_DIRECTIONS.down}
                />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    render() {
      return (
        <Component
          {...this.props}
          renderScrollOverlay={this.renderScrollOverlay}
          scrollY={this.state.scrollY}
        />
      );
    }
  }

  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    scrollY: PropTypes.number,
    renderScrollOverlay: PropTypes.func,
  };
  return WrappedComponent;
}

export default withScrollOverlay;
