import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SURFACE_COLORS, TEXT_COLORS, ICON_COLORS } from 'Style/colors';
import { PAGE_STYLES } from 'Style/layout';
import {
  BODY_TYPES,
  CONTENT_STORY_HEADING_TYPES,
  FONT_WEIGHTS,
} from 'Style/typography';
import WebLink from 'Webapp/shared/app/components/web-link';
import Badge from 'Webapp/shared/app/components/badge';
import CuratorAttribution from 'Webapp/shared/app/components/attribution/curator';
import Bullet from 'Webapp/shared/app/components/bullet';
import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar';
import MagazineContributorsDescription from 'Webapp/shared/app/components/magazine-contributors-description';
import { StyledIcon } from 'ComponentLibrary/icons/icon';

import SectionUtil from 'Utils/content/section-util';
import { getAuthorUrl } from 'Utils/content/flipboard-urls';

import SocialActions from 'Webapp/shared/app/components/social-actions';

import withT from 'ComponentLibrary/hocs/withT';
import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';
import { ItemActionButtonType } from 'Webapp/enums';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

const MAX_CONTRIBUTORS = 3;

const TitleNoSocial = styled.div({
  ...CONTENT_STORY_HEADING_TYPES.LARGE,
  marginBottom: SPACING.LARGE,
  overflowWrap: 'break-word',
  textAlign: 'center',
});

const NoVeilBanner = styled.div({
  backgroundColor: SURFACE_COLORS.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: SPACING.BASE4X,
  paddingTop: SPACING.BASE4X,
});

const BannerContent = styled.div({
  width: '100%',
  ...PAGE_STYLES.CENTERED_COLUMN,
});

const BulletSeparator = styled(Bullet)({ margin: `0 ${SPACING.SMALL}` });
const TitleRowWithSocial = styled.div({
  display: 'grid',
  gridTemplateColumns: `auto 1fr`,
  marginTop: SPACING.LARGE,
  alignItems: 'center',
});
const Title = styled.div({
  ...CONTENT_STORY_HEADING_TYPES.LARGE,
  overflowWrap: 'break-word',
});

const StyleContributorList = styled.p(BODY_TYPES.XSMALL_STANDARD, {
  fontWeight: FONT_WEIGHTS.NORMAL,
  color: TEXT_COLORS.tertiary,
  a: { fontWeight: FONT_WEIGHTS.MEDIUM, color: TEXT_COLORS.primary },
});

const FooterSocial = styled(SocialActions)({
  margin: `${SPACING.BASE} auto ${SPACING.BASE} ${SPACING.XLARGE}`,
  ...BREAKPOINTS.phone({ width: '100%', marginLeft: 0 }),
  backgroundColor: 'unset',

  '.social-actions_invite-button, .social-actions__follow-button': {
    marginRight: SPACING.XLARGE,
  },
  '.social-actions_edit-magazine-button': {
    marginRight: SPACING.XLARGE,
    borderColor: TEXT_COLORS.tertiary,
    lineHeight: '18px',
  },
  '.social-actions_invite-button, .social-actions_edit-magazine-button': {
    textAlign: 'left',
    svg: {
      display: 'none',
    },
    ...BREAKPOINTS.tabletPortraitUp({
      svg: {
        display: 'block',
      },
      alignItems: 'center',
      color: TEXT_COLORS.secondaryDark,
      padding: SPACING.BASE,
      backgroundColor: TEXT_COLORS.overlay,
      border: `1px solid ${TEXT_COLORS.secondary}`,
      '&:focus, &:active': {
        color: TEXT_COLORS.secondaryDark,
        background: 'unset',
      },
    }),
  },

  '.social-actions__button': {
    padding: `0 ${SPACING.MEDIUM}`,
  },
  '.share-icon, .flip-icon, .like-icon': {
    color: TEXT_COLORS.primary,
  },

  '.meatballs-menu-icon__meatball': {
    backgroundColor: TEXT_COLORS.primary,
  },
  [StyledIcon]: {
    color: ICON_COLORS.emphasisDark,
    ...BREAKPOINTS.phone({ color: ICON_COLORS.emphasis }),
  },
});

const OldFooterCurator = styled(CuratorAttribution)(BODY_TYPES.SMALL_STANDARD, {
  marginTop: SPACING.BASE4X,
  gap: SPACING.LARGE,
  color: TEXT_COLORS.secondary,
});

const FooterCurator = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: SPACING.MEDIUM,
});

const CenteredBadge = styled(Badge)({
  justifyContent: 'center',
});

function MagazineFooter({
  section,
  contributors,
  withVeil,
  featureFlags,
  isPhone,
  t,
}) {
  if (!section) {
    return null;
  }
  if (withVeil) {
    return (
      <BannerContent>
        <TitleNoSocial>{section.title}</TitleNoSocial>
        <CenteredBadge>{t('magazine')}</CenteredBadge>
      </BannerContent>
    );
  }
  const magazineAuthor = section.sectionItemAuthor || section.author;
  const { author } = section;
  const authorURL = getAuthorUrl(author);

  const storyCount = SectionUtil.getMetric(section, 'articles')?.raw;

  const buttonSet = isPhone
    ? [ItemActionButtonType.FOLLOW]
    : [
        ItemActionButtonType.INVITE_CONTRIBUTOR,
        !featureFlags.INVITE_MAGAZINE_CONTRIBUTOR &&
          ItemActionButtonType.ACCEPT_INVITE,
        ...BUTTON_SETS.MAGAZINE,
      ];
  const actionSheetButtonSet = isPhone
    ? [
        ItemActionButtonType.ADD_FAVORITE,
        ItemActionButtonType.INVITE_CONTRIBUTOR,
      ]
    : [ItemActionButtonType.ADD_FAVORITE];
  const allContributors = author ? [author, ...contributors] : contributors;
  return (
    <NoVeilBanner>
      <BannerContent>
        <Badge>
          {t('magazine')}
          {storyCount && (
            <React.Fragment>
              <BulletSeparator />
              {t('story_count', { count: storyCount })}
            </React.Fragment>
          )}
        </Badge>
        <TitleRowWithSocial>
          <Title>{section.title}</Title>
          <FooterSocial
            section={section}
            buttonSet={buttonSet}
            actionSheetButtonSet={actionSheetButtonSet}
          />
        </TitleRowWithSocial>
        {featureFlags.NEW_MAGAZINE_COVER ? (
          <FooterCurator>
            <WebLink href={authorURL}>
              <AuthorAvatar author={magazineAuthor} />
            </WebLink>
            <StyleContributorList>
              <MagazineContributorsDescription
                profiles={allContributors.slice(0, MAX_CONTRIBUTORS)}
                undisplayedCount={0}
                isNewMagazineCover={featureFlags.NEW_MAGAZINE_COVER}
                isMagazineContributorBanner
              />
            </StyleContributorList>
          </FooterCurator>
        ) : (
          <OldFooterCurator item={section} />
        )}
      </BannerContent>
    </NoVeilBanner>
  );
}

MagazineFooter.propTypes = {
  section: PropTypes.object,
  contributors: PropTypes.array,
  withVeil: PropTypes.bool,
  featureFlags: PropTypes.object.isRequired,
  isPhone: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default connector(
  connectResponsive,
  connectFeatureFlags,
)(withT(MagazineFooter));
