import { connect } from 'react-redux';

import MagazineHeader from 'Webapp/shared/app/views/section/magazine/magazine-header';

import FlapUtil from 'Utils/content/flap-util';

const mapStateToProps = ({ sections }, { section }) => {
  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(section.remoteid, sections.entries),
  );
  return {
    metrics: currentSection.metrics,
  };
};

const MagazineHeaderContainer = connect(mapStateToProps)(MagazineHeader);

export default MagazineHeaderContainer;
