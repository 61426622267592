import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { PAGE_STYLES } from 'Style/layout';

import replaceTags from 'Utils/i18n/replace-tags';
import { projectionFromSection } from 'Utils/content/item-util';
import FlapUtil from 'Utils/content/flap-util';

import withT from 'ComponentLibrary/hocs/withT';

import CTABannerRedesigned from 'Webapp/shared/app/components/cta-banner-redesigned';
import ContextSection from 'Webapp/shared/app/components/context-section';
import AuthorAttribution, {
  STYLE_VARIATIONS as AUTHOR_ATTRIBUTION_STYLE_VARIATIONS,
} from 'Webapp/shared/app/components/attribution/author';

const RELATED_SECTION_ARTICLE_LIMIT = 6;

const RelatedSection = styled(ContextSection)({
  ...PAGE_STYLES.CENTERED_COLUMN,
  marginTop: SPACING.BASE6X,
  marginBottom: SPACING.BASE6X,
});
class MagazineRelated extends Component {
  relatedSectionHeading(relatedSection, translationKey) {
    const { t } = this.props;
    return (
      <span>
        {replaceTags(
          t(translationKey, { author: relatedSection.authorDisplayName }),
          {
            authorLink: () => (
              <AuthorAttribution
                author={relatedSection.author}
                styleVariation={AUTHOR_ATTRIBUTION_STYLE_VARIATIONS.RED}
              />
            ),
          },
        )}
      </span>
    );
  }

  render() {
    const { curatorStoryboardSection, curatorMagazineSection } = this.props;
    const relatedMagazineItems = curatorMagazineSection?.magazineProjections
      ?.map((ms) => projectionFromSection(ms))
      ?.filter(
        (m) =>
          !m.isPrivate &&
          !FlapUtil.isRemoteIdSectionMatch(m.remoteid, this.props.section),
      );

    return (
      <React.Fragment>
        {curatorMagazineSection?.magazineProjections?.length > 0 && (
          <RelatedSection
            section={curatorMagazineSection}
            itemsOverride={relatedMagazineItems}
            sectionItemLimit={RELATED_SECTION_ARTICLE_LIMIT}
            condensedItems
            headerOverride={this.relatedSectionHeading(
              curatorMagazineSection,
              'more_magazines_by',
            )}
            hideSeeMore
          />
        )}
        <CTABannerRedesigned key="cta-banner-redesigned" />
        {curatorStoryboardSection?.items?.length > 0 && (
          <RelatedSection
            section={curatorStoryboardSection}
            sectionItemLimit={RELATED_SECTION_ARTICLE_LIMIT}
            condensedItems
            headerOverride={this.relatedSectionHeading(
              curatorStoryboardSection,
              'storyboards_by',
            )}
            hideSeeMore
          />
        )}
      </React.Fragment>
    );
  }
}

MagazineRelated.propTypes = {
  section: PropTypes.object.isRequired,
  curatorMagazineSection: PropTypes.object,
  curatorStoryboardSection: PropTypes.object,
  t: PropTypes.func.isRequired,
};

MagazineRelated.defaultProps = {};

export default withT(MagazineRelated);
