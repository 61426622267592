import React from 'react';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import connector from 'Utils/connector';
import connectMagazineSubscription, {
  ConnectMagazineSubscriptionProps,
} from 'Webapp/shared/app/connectors/connectMagazineSubscription';
import connectUsageSetNavFrom, {
  ConnectUsageSetNavFromProps,
} from 'Webapp/shared/app/connectors/connectUsageSetNavFrom';
import { getIsSubscribed } from 'Webapp/shared/concepts/magazine-subscriptions';
import { USAGE_EVENT_NAMES } from 'Webapp/utils/analytics/usage';

interface MagazineSubscriptionButtonPassedProps {
  magazine: Flipboard.Section;
}

type MagazineSubscriptionButtonProps = MagazineSubscriptionButtonPassedProps &
  ConnectMagazineSubscriptionProps &
  ConnectUsageSetNavFromProps;

class MagazineSubscriptionButton extends React.Component<MagazineSubscriptionButtonProps> {
  componentDidMount() {
    const { loadMagazineSubscriptions, loaded, loading } = this.props;
    if (!loaded && !loading) {
      loadMagazineSubscriptions();
    }
  }
  isSubscribed = () => {
    const { subscriptions, magazine } = this.props;
    return getIsSubscribed(subscriptions, magazine);
  };
  handleClick = async () => {
    const {
      toggleMagazineSubscription,
      loadMagazineSubscriptions,
      magazine,
      usageSetNavFrom,
    } = this.props;
    // value should be 'settings' when we add a settings page
    usageSetNavFrom(USAGE_EVENT_NAMES.MAGAZINE_SUBSCRIBE, 'cover');
    await toggleMagazineSubscription(magazine);
    await loadMagazineSubscriptions();
  };
  render() {
    const { loading, loaded } = this.props;
    if (!loaded) {
      return null;
    }
    return (
      <Button
        name="magazine_subscription"
        label={this.isSubscribed() ? '🚭' : '🔔'}
        onClick={this.handleClick}
        styleVariation={StyleVariations.SECONDARY}
        disabled={loading}
      />
    );
  }
}

export default connector<MagazineSubscriptionButtonProps>(
  connectUsageSetNavFrom,
  connectMagazineSubscription,
)(MagazineSubscriptionButton);
