import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import {
  TEXT_COLORS,
  SURFACE_COLORS,
  DefaultTextColorStyle,
} from 'Style/colors';
import { PAGE_STYLES, LAYERS, LAYOUT_SIZES } from 'Style/layout';
import {
  BODY_TYPES,
  CONTENT_STORY_HEADING_TYPES,
  CONTENT_BODY,
  UI_HEADING_TYPES,
  UI_SUBHEADING_TYPES,
} from 'Style/typography';
import { getPixelNumberValue } from 'Style/style-helpers';

import { getImageAttribution } from 'Utils/image-util';
import { truncateText } from 'Utils/text';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import SectionUtil from 'Webapp/utils/content/section-util';

import Image, { THEMES } from 'Webapp/shared/app/components/base/image';
import SocialActions, {
  ACTIONS_ALIGNMENTS,
} from 'Webapp/shared/app/components/social-actions';
import ItemSocialActions from 'Webapp/shared/app/components/item/item-social-actions';
import Badge from 'Webapp/shared/app/components/badge';
import Bullet from 'Webapp/shared/app/components/bullet';
import CopyFederatedUsername from 'Webapp/shared/app/components/CopyFederatedUsername';

import MagazineSubscriptionButton from 'Webapp/shared/app/components/magazine-subscription-button';
import { StyledItemMetric } from 'Webapp/shared/app/components/item-actions/item-metric';
import DefaultItemActionButton from 'Webapp/shared/app/components/item-actions/default-item-action-button';
import { ItemActionButtonType, MagazineMetricType } from 'Webapp/enums';
import LockIcon from 'ComponentLibrary/icons/lock';
import { MeatballButton } from 'ComponentLibrary/icons/meatballs-menu';
import {
  ProfileStackList,
  ProfileStackItem,
} from 'Webapp/shared/app/components/profile-stack';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

const MagazineContributors = codeSplitLoad('MagazineContributors');

const MagazineInviteBanner = codeSplitLoad('MagazineInviteBanner');
const MagazineContributorBanner = codeSplitLoad('MagazineContributorBanner');

/**
 * Found some old magazines that exceed our current allowed maximum.
 * We likely don't care about most of them, so just truncate it down to our
 * current limit so the page isn't ugly.
 */
const MAX_DISPLAYED_DESCRIPTION_LENGTH = 500;

const HEADER_HEIGHT = LAYOUT_SIZES.MAGAZINE_HEADER_HEIGHT;
const HEADER_HEIGHT_PHONE = LAYOUT_SIZES.MAGAZINE_HEADER_HEIGHT_PHONE;

const HeaderWrapper = styled.header({
  position: 'relative',
  minHeight: HEADER_HEIGHT,
  ...BREAKPOINTS.phone({ minHeight: HEADER_HEIGHT_PHONE }),
});
const HeaderImageWrapper = styled.div({
  height: '100%',
  width: '100%',
  position: 'absolute',
});
const HeaderImage = styled(Image)(
  (props) =>
    props.isAmp && {
      'amp-img': {
        height: HEADER_HEIGHT,
        ...BREAKPOINTS.phone({ minHeight: HEADER_HEIGHT_PHONE }),
        img: { objectFit: 'cover' },
      },
    },
);
const HeaderOverlay = styled.div({
  position: 'relative',
  width: '100%',
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.0001) 100%)',
});
const HeaderContent = styled.div(
  {
    minHeight: HEADER_HEIGHT,
    paddingTop: SPACING.BASE6X,
    paddingBottom: SPACING.LARGE,
    position: 'relative',
    ...PAGE_STYLES.CENTERED_COLUMN,
    color: TEXT_COLORS.overlay,
  },
  BREAKPOINTS.phone({
    minHeight: HEADER_HEIGHT_PHONE,
    paddingTop: SPACING.BASE4X,
    paddingBottom: SPACING.BASE10X,
  }),
  BREAKPOINTS.tablet({
    paddingLeft: SPACING.BASE8X,
    paddingTop: SPACING.XLARGE,
  }),
);
const HeaderMagazineFederatedName = styled.div(BODY_TYPES.SMALL_STANDARD, {
  position: 'absolute',
  bottom: 0,
  right: 0,
});

const HeaderTitleContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  '.meatballs-menu-icon__meatball': {
    backgroundColor: TEXT_COLORS.overlay,
  },
  '.social-actions': {
    marginLeft: 'auto',
  },
});

const PrivateMagazineLabel = styled.span(UI_SUBHEADING_TYPES.SMALL, {
  textTransform: 'uppercase',
  color: TEXT_COLORS.overlay,
  marginLeft: SPACING.SMALL,
});
const MagazineHeaderWrapper = styled.div({
  background: SURFACE_COLORS.secondary,
  position: 'relative',
});
const PrivateMagazineBadgeContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
  color: TEXT_COLORS.overlay,
  marginLeft: SPACING.SMALL,
});

const HeaderTitle = styled.h1(
  ({ isNewCoverMagazine }) => {
    if (isNewCoverMagazine) {
      return {
        ...UI_HEADING_TYPES.LARGE,
        marginTop: SPACING.BASE4X,
        marginBottom: SPACING.LARGE,
        ...BREAKPOINTS.desktopUp({
          ...UI_HEADING_TYPES.XLARGE,
          color: TEXT_COLORS.overlay,
          fontSize: '48px',
          lineHeight: '52px',
        }),
      };
    }
    return {
      ...CONTENT_STORY_HEADING_TYPES.LARGE_OVERLAY,
      marginTop: SPACING.LARGE,
    };
  },
  {
    color: TEXT_COLORS.overlay,
    maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
    overflowWrap: 'break-word',
  },
);

const HeaderDescription = styled.p(
  ({ isNewCoverMagazine }) => {
    if (isNewCoverMagazine) {
      return BODY_TYPES.SMALL_EMPHASIS_1;
    }
    return CONTENT_BODY;
  },
  {
    color: TEXT_COLORS.overlay,
    marginTop: SPACING.LARGE,
    maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
    overflowWrap: 'break-word',
  },
);
const HeaderCurators = styled(MagazineContributors)(
  BODY_TYPES.LARGE_STANDARD,
  {
    marginTop: SPACING.XLARGE,
    gap: SPACING.LARGE,
    marginBottom: '0px',
    ...DefaultTextColorStyle(true),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  ({ isNewMagazineCover }) => {
    if (isNewMagazineCover) {
      return {
        gap: SPACING.MEDIUM,
        marginBottom: SPACING.LARGE,
        [ProfileStackList]: {
          marginLeft: '5px',
        },
        [ProfileStackItem]: {
          marginLeft: '-5px',
        },
      };
    }
  },
);

const HeaderImageAttribution = styled.span(BODY_TYPES.XSMALL_STANDARD, {
  color: TEXT_COLORS.overlay,
  marginTop: SPACING.BASE6X,
  mixBlendMode: 'normal',
  opacity: '0.6',
});
const HeaderMetaWrapper = styled.div(
  {
    position: 'relative',
    bottom: '0',
    width: '100%',
    zIndex: LAYERS.ABOVE_STICKY_BANNER,
    backgroundColor: 'rgba(34, 34, 34, 0.6)',
  },
  BREAKPOINTS.phone({
    paddingTop: SPACING.BASE,
    paddingBottom: SPACING.BASE,
    display: 'flex',
  }),
);
const HeaderMeta = styled.div({
  ...PAGE_STYLES.CENTERED_COLUMN,
  ...BODY_TYPES.SMALL_STANDARD,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  li: {
    backgroundColor: 'unset',
  },
  '.sub-nav': {
    ...DefaultTextColorStyle(true),
    margin: '0px',
  },
  ...BREAKPOINTS.phone({
    padding: `0px ${SPACING.LARGE} 0px ${SPACING.LARGE}`,
  }),
});
const MetricsBlock = styled.div(BODY_TYPES.SMALL_STANDARD, {
  display: 'flex',
  flexDirection: 'row',
  color: TEXT_COLORS.overlay,
  marginTop: SPACING.LARGE,
  alignItems: 'center',
});
const BulletSeparator = styled(Bullet)(
  { margin: `0 ${SPACING.LARGE}` },
  BREAKPOINTS.phone({
    margin: `0 ${SPACING.BASE}`,
  }),
);

const HeaderSocial = styled(SocialActions)({
  backgroundColor: 'unset',
  [DefaultItemActionButton]: { padding: `${SPACING.BASE} ${SPACING.MEDIUM}` },
  '.social-actions__button': {
    padding: `0 ${SPACING.MEDIUM}`,
  },
  '.meatballs-menu-icon__meatball': {
    backgroundColor: TEXT_COLORS.overlay,
  },
  '.social-actions__content': {
    display: 'flex',
    flexDirection: 'row',
    gap: SPACING.LARGE,
    padding: SPACING.XSMALL,
    ...BREAKPOINTS.phone({
      gap: SPACING.LARGE,
      [MeatballButton]: {
        paddingLeft: SPACING.BASE,
        paddingRight: SPACING.BASE,
        width: 'auto',
      },
    }),
  },
});

const MagazineHeaderSocialButtons = styled(ItemSocialActions)({
  marginRight: 'auto',
  [DefaultItemActionButton]: {
    ...DefaultTextColorStyle(true, false),
    ...BREAKPOINTS.phone({
      padding: SPACING.BASE,
    }),
  },
  [StyledItemMetric]: DefaultTextColorStyle(true),
  '.social-actions__content': {
    position: 'relative',
    left: `-${SPACING.MEDIUM}`,
  },
});

const IGNORED_METRICS_KEYS = [MagazineMetricType.viewers];

class MagazineHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coverImageFailed: false,
    };
  }

  handleCoverImageFailure = () => {
    this.setState({ coverImageFailed: true });
  };

  render() {
    const {
      section,
      metrics,
      isPhone,
      isDesktop,
      contributors,
      isAmp,
      featureFlags,
      isCurrentUserContributor,
      t,
    } = this.props;
    const { title, description, coverImage } = section;
    const { coverImageFailed } = this.state;

    const attributionText = coverImage && getImageAttribution(coverImage, t);
    const { author } = section;
    const allContributors = author ? [author, ...contributors] : contributors;

    const actionSheetButtonSet = [
      ItemActionButtonType.ADD_FAVORITE,
      !featureFlags.NEW_MAGAZINE_COVER &&
        ItemActionButtonType.INVITE_CONTRIBUTOR,
      ItemActionButtonType.LEAVE_MAGAZINE,
      isPhone &&
        featureFlags.ADD_TO_MAGAZINE_BUTTON &&
        !featureFlags.NEW_MAGAZINE_COVER &&
        ItemActionButtonType.CREATE_STORY,
    ];

    const headerSocialButtonSet = [
      !isCurrentUserContributor && ItemActionButtonType.FOLLOW,
      ItemActionButtonType.EDIT_MAGAZINE,
      !isPhone &&
        featureFlags.ADD_TO_MAGAZINE_BUTTON &&
        !featureFlags.NEW_MAGAZINE_COVER &&
        ItemActionButtonType.CREATE_STORY,
      !featureFlags.INVITE_MAGAZINE_CONTRIBUTOR &&
        ItemActionButtonType.ACCEPT_INVITE,
    ];

    const magazineHeaderButtonSet = [ItemActionButtonType.LIKE];

    if (featureFlags.MAGAZINE_COVER_COMMENTS) {
      magazineHeaderButtonSet.push(ItemActionButtonType.COMMENTS);
    }

    if (!section.isPrivate) {
      magazineHeaderButtonSet.push(ItemActionButtonType.FLIP);
      magazineHeaderButtonSet.push(ItemActionButtonType.SHARE);
    }

    return (
      <>
        <MagazineHeaderWrapper>
          {featureFlags.INVITE_MAGAZINE_CONTRIBUTOR &&
            !isCurrentUserContributor && (
              <MagazineInviteBanner section={section} />
            )}
          <HeaderWrapper>
            {coverImage && !coverImageFailed && (
              <HeaderImageWrapper>
                <HeaderImage
                  image={coverImage}
                  alt={t('magazine_cover_image_alt', { magTitle: title })}
                  onError={this.handleCoverImageFailure}
                  crop
                  ampHeight={getPixelNumberValue(
                    isPhone ? HEADER_HEIGHT_PHONE : HEADER_HEIGHT,
                  )}
                  isAmp={isAmp}
                  variant={THEMES.large}
                />
              </HeaderImageWrapper>
            )}
            <HeaderOverlay>
              <HeaderContent>
                <HeaderTitleContainer>
                  {featureFlags.NEW_MAGAZINE_COVER ? (
                    section.isPrivate && (
                      <PrivateMagazineBadgeContainer>
                        <LockIcon size={16} />
                        <PrivateMagazineLabel>
                          {t('private')}
                        </PrivateMagazineLabel>
                      </PrivateMagazineBadgeContainer>
                    )
                  ) : (
                    <Badge isOverlay>{t('magazine')}</Badge>
                  )}
                </HeaderTitleContainer>
                {featureFlags.NEW_MAGAZINE_COVER && (
                  <HeaderCurators
                    section={section}
                    profiles={allContributors}
                    avatarSize={32}
                    isNewMagazine
                    maxDisplayCount={3}
                    isNewMagazineCover={featureFlags.NEW_MAGAZINE_COVER}
                  />
                )}
                <HeaderTitle
                  isNewCoverMagazine={featureFlags.NEW_MAGAZINE_COVER}
                >
                  {title}
                </HeaderTitle>
                {description && (
                  <HeaderDescription
                    isNewCoverMagazine={featureFlags.NEW_MAGAZINE_COVER}
                  >
                    {truncateText(
                      description,
                      MAX_DISPLAYED_DESCRIPTION_LENGTH,
                      true,
                    )}
                  </HeaderDescription>
                )}
                {metrics?.profileMetrics?.length && (
                  <MetricsBlock>
                    {metrics?.profileMetrics?.reduce(
                      (components, metric, i, source) => {
                        if (
                          featureFlags.NEW_MAGAZINE_COVER &&
                          IGNORED_METRICS_KEYS.includes(
                            metric.displayName.toLowerCase(),
                          )
                        ) {
                          return components;
                        }

                        components.push(
                          <span
                            key={metric.displayName}
                          >{`${metric.value} ${metric.displayName}`}</span>,
                        );
                        if (i === source.length - 1) {
                          return components;
                        }

                        components.push(<BulletSeparator key={i} />);
                        return components;
                      },
                      [],
                    )}
                  </MetricsBlock>
                )}
                {!featureFlags.NEW_MAGAZINE_COVER && (
                  <HeaderCurators
                    section={section}
                    profiles={allContributors}
                    avatarSize={isDesktop ? 64 : 48}
                    isNewMagazine
                  />
                )}
                {featureFlags.MAGAZINE_SUBSCRIPTIONS && !section.isPrivate && (
                  <MagazineSubscriptionButton magazine={section} />
                )}
                {attributionText && (
                  <HeaderImageAttribution>
                    {attributionText}
                  </HeaderImageAttribution>
                )}
                {section.magazineFederatedName && (
                  <HeaderMagazineFederatedName>
                    <CopyFederatedUsername
                      username={section.magazineFederatedName}
                      tKey="magazine_federated_username_copied"
                      overlay
                    />
                  </HeaderMagazineFederatedName>
                )}
              </HeaderContent>
            </HeaderOverlay>
            <HeaderMetaWrapper>
              <HeaderMeta>
                <MagazineHeaderSocialButtons
                  item={SectionUtil.getSectionCoverItem(section)}
                  section={section}
                  buttonSet={magazineHeaderButtonSet}
                  hideMeatball
                  showSocialMetrics
                  alignment={ACTIONS_ALIGNMENTS.LEFT}
                />
                <HeaderSocial
                  section={section}
                  buttonSet={headerSocialButtonSet}
                  actionSheetButtonSet={actionSheetButtonSet}
                />
              </HeaderMeta>
            </HeaderMetaWrapper>
          </HeaderWrapper>
        </MagazineHeaderWrapper>
        {featureFlags.NEW_MAGAZINE_COVER && isCurrentUserContributor && (
          <MagazineContributorBanner
            title={title}
            coverImage={coverImage}
            coverImageFailed={coverImageFailed}
            section={section}
            allContributors={allContributors}
          />
        )}
      </>
    );
  }
}

MagazineHeader.propTypes = {
  section: PropTypes.object.isRequired,
  contributors: PropTypes.array,
  metrics: PropTypes.object,
  t: PropTypes.func.isRequired,
  isPhone: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isAmp: PropTypes.bool,
  isCurrentUserContributor: PropTypes.bool,
  featureFlags: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  toastShowInfoAction: PropTypes.func.isRequired,
};

export default connector(
  connectAmp,
  connectResponsive,
  connectFeatureFlags,
  connectModal,
)(withT(MagazineHeader));
