import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  usageTrackReachedItemEnd,
  usageTrackReachedSectionEnd,
} from 'Webapp/shared/app/redux/actions/usage-actions';

import MagazineBody from 'Webapp/shared/app/views/section/magazine/magazine-body';

import FlapUtil from 'Utils/content/flap-util';
import SectionUtil from 'Utils/content/section-util';

const mapStateToProps = ({ sections, auth }, { section }) => {
  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(section.remoteid, sections.entries),
  );
  const isLoading = SectionUtil.isSectionLoading(
    currentSection.requestedRemoteId,
    sections.loading,
  );

  return {
    uid: auth.uid,
    items: currentSection.items,
    isLoading,
    neverLoadMore: currentSection.neverLoadMore,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      usageTrackReachedItemEnd,
      usageTrackReachedSectionEnd,
    },
    dispatch,
  );

const MagazineBodyContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MagazineBody);

export default MagazineBodyContainer;
